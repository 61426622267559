@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Poppins", sans-serif;
}

*:focus {
  outline: none;
}

.text-link,
.text-link:hover {
  text-decoration: none;
  color: #4db282;
}

.input-group-text {
  background: #fff;
  border-color: #696969;
  border-left: 0;
}

.input-group input {
  border-right: 0 !important;
}

ul.my-list {
  list-style: none;
}

ul.my-list li {
  margin-bottom: 15px;
  position: relative;
  padding-left: 20px;
}

ul.my-list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #4db282;
}

.section-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  margin-bottom: 65px;
  color: #13123d;
}

.section-gap {
  /* padding: 100px 0; */
}

.btn-main {
  background-color: #4db282 !important;
  color: #fff;
  border: 2px solid #4db282;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 24px;
  /* border-radius: 64px; */
  text-transform: capitalize;
}

.btn-main a {
  color: #fff;
  text-decoration-color: #4db282;
}

.btn-main:hover {
  border: 2px solid #4db282;
  color: #4db282;
}

.btn-main.btn-sm {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  padding: 8px 20px;
}

.btn-main.btn-sm:hover {
  border: 2px solid #4db282;
  color: #fff;
}

.btn-outline {
  font-size: 14px;
  text-decoration: none;
  font-weight: 500;
  padding: 8px 20px;
  border-radius: 30px;
  color: #212121;
  background-color: #ddd;
  border: 2px solid #ddd;
}

.btn-outline:hover {
  color: #212121;
}

.navbar__wrapper {
  /* position: fixed; */
  position: sticky;

  width: 100%;
  padding: 15px 0;
  background: #fff;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
}

.navbar__wrapper.fixed {
  z-index: 999;
  -webkit-box-shadow: 0 0 20px 2px rgba(145, 145, 145, 0.29);
  box-shadow: 0 0 20px 2px rgba(145, 145, 145, 0.29);
}

.navbar__wrapper .navbar-expand .navbar-nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar__wrapper .navbar-brand {
  font-weight: 600;
  font-size: 22px;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: #8b8b8b;
}

.navbar__wrapper .navbar-brand img {
  padding-right: 10px;
}

.navbar__wrapper .nav-link.call-link {
  font-weight: 600;
  font-size: 22px;
  margin-right: 20px;
  color: #8b8b8b;
}

.navbar__wrapper .nav-link.call-link img {
  padding-right: 10px;
  width: 60px;
}

.navbar__wrapper .btn-main {
  margin-left: 30px;
}

.main-banner {
  /* padding-top: 100px; */
}

.main-banner .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-banner .banner-img {
  text-align: right;
}

.main-banner .banner-text {
  margin-top: 60px;
}

.main-banner .banner-text h4 {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  text-transform: uppercase;
  color: #13123d;
  position: relative;
  padding-left: 30px;
}

.main-banner .banner-text h4::after {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #13123d;
}

.main-banner .banner-text h1 {
  position: relative;
  font-weight: 700;
  font-size: 60px;
  line-height: 76px;
  color: #343434;
}

.main-banner .banner-text h1 span {
  color: #13123d;
  display: block;
}

.main-banner .drp-icon {
  padding: 5px 8px;
}

.two-col-hov-wrapper .row .col-lg-6:nth-child(3) .single-col-hov,
.two-col-hov-wrapper .row .col-lg-6:nth-child(4) .single-col-hov {
  margin-bottom: 0px;
}

.two-col-hov-wrapper .single-col-hov {
  margin: 0 auto;
  position: relative;
  margin-bottom: 30px;
}

.two-col-hov-wrapper .single-col-hov img {
  height: 481px;
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.two-col-hov-wrapper .single-col-hov .single-col-desc {
  background: #13123d;
  padding: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.two-col-hov-wrapper .single-col-hov .single-col-desc h5 {
  font-size: 24px;
  line-height: 49px;
  letter-spacing: 0.05em;
  color: #fff;
}

.two-col-hov-wrapper .single-col-hov .single-col-desc p {
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.footer_wrapper {
  background: #2c2d30;
  padding: 15px 0;
}

.footer_wrapper p {
  color: #fff;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;
}

.footer_wrapper p span {
  color: #4db282;
}

.footer_wrapper .ft-links {
  text-align: right;
}

.footer_wrapper .ft-links a {
  color: #fff;
  text-decoration: none;
  padding-left: 30px;
}

.about-feature-wrapper p {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #7d7d7d;
  margin-bottom: 10px;
}

.about-feature-wrapper .arrow-feature {
  margin-top: 30px;
}

.about-feature-wrapper .arrow-feature h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 44px;
  color: #13123d;
  width: 80%;
  position: relative;
}

.about-feature-wrapper .arrow-feature h4::after {
  content: url("../Images/Arrow.png");
  position: absolute;
}

.about-feature-wrapper .col-lg-3:nth-child(odd) .arrow-feature h4:after {
  top: -120px;
  right: -90px;
}

.about-feature-wrapper .col-lg-3:nth-child(even) .arrow-feature h4:after {
  top: 30px;
  right: -90px;
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.about-feature-wrapper .col-lg-3:last-child .arrow-feature h4:after {
  content: none;
}

.fleet-wrapper .container {
  width: 60%;
}

.fleet-wrapper .fleet-box {
  margin: 0 auto;
  width: 90%;
  margin-bottom: 50px;
  text-align: center;
  background: #fbfcff;
  -webkit-box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 30px;
}

.fleet-wrapper .fleet-box img {
  width: 330px;
  height: 160px;
  -o-object-fit: contain;
  object-fit: contain;
}

.fleet-wrapper .fleet-box h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #565656;
  margin-bottom: 30px;
}

.fleet-wrapper .fleet-box .capacity-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  font-size: 24px;
  color: #7e7e80;
  line-height: 45px;
}

.fleet-wrapper .fleet-box .capacity-wrap img {
  width: 24px;
  margin-right: 10px;
  height: auto;
}

.get-touch-wrapper {
  background: #edf1ff;
  padding: 100px 0;
}

.get-touch-wrapper .map-frame {
  width: 100%;
  height: 700px;
  border-radius: 30px;
}

.call-to-book-wrapper .container {
  background: #13123d;
  border-radius: 30px;
  position: relative;
  margin-top: 90px;
  margin-bottom: 60px;
  padding: 30px;
}

.call-to-book-wrapper .container .car-name {
  position: absolute;
  top: -100px;
  width: 350px;
}

.call-to-book-wrapper .container h5 {
  color: #fff;
  font-weight: 400;
  margin-bottom: 30px;
  font-size: 28px;
}

.call-to-book-wrapper .container a {
  font-weight: 600;
  font-size: 36px;
  color: #fff;
  text-decoration: none;
}

.touch-form {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 60px;
}

.touch-form h4 {
  font-weight: 400;
  font-size: 36px;
  line-height: 56px;
  text-transform: capitalize;
  color: #273240;
}

.touch-form input {
  height: 55px;
  border: 1px solid #dedede;
  border-radius: 6px;
}

.touch-form .btn-main {
  width: 100%;
}

.quote-form {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  padding: 30px;
  margin-top: 10px;
}

.quote-form h4 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  color: #273240;
}

.quote-form select,
.quote-form input {
  height: 55px;
  border: 1px solid #696969;
  border-radius: 6px;
}

.quote-form select.pac-target-input,
.quote-form input.pac-target-input {
  border-right: 0;
  width: 100%;
  padding-left: 15px;
}

.hav-google-data .input-group div input,
.react-datepicker-wrapper input {
  border-radius: 6px 0 0 6px;
}
.hav-google-data .input-group div {
  flex: 1 1;
}

.hav-google-data .via-ctn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.quote-form .btn-main {
  width: 50%;
  margin-top: 15px;
}

.quote-form .nav-tabs {
  gap: 9px;
  border-bottom: 0;
}

.quote-form .nav-tabs .nav-item {
  width: 48%;
  margin-bottom: 20px;
}

.quote-form .nav-tabs .nav-link {
  border: 2px solid #b9b9b9;
  border-radius: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #b9b9b9;
  padding: 15px 32px;
  width: 100%;
}

.quote-form .nav-tabs .nav-link.active {
  color: #4db282;
  border: 3px solid #4db282;
}

.quote-form select {
  border-right: 0;
}

.quote-form label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #6d6d6d;
}

.inner-banner {
  position: relative;
  z-index: 9;
  /* margin-top: 160px; */
}

.inner-banner .ride-main-details {
  background: #ffffff;
  -webkit-box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  padding: 25px;
  border-radius: 20px;
  margin: 0.5rem;
}

@media (max-width: 365px) {
  .inner-banner .date-time-wrapper {
    gap: 20px !important;
  }

  .inner-banner .date-time-wrapper .date-wrap {
    font-size: 28px !important;
    font-weight: 600 !important;
    gap: 8px !important;
  }

  .inner-banner .date-time-wrapper .date-wrap span {
    font-size: 21px !important;
  }

  .inner-banner .date-time-wrapper div {
    padding: 5px 16px !important;
  }

  .inner-banner .date-time-wrapper .time-wrap {
    font-size: 19px !important;
  }
}

.inner-banner h1 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 10px;
}

.inner-banner h5 {
  font-size: 16px;
  font-weight: 400;
}

.inner-banner .route-info {
  margin-bottom: 20px;
}

.inner-banner .route-info p {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 400;
  position: relative;
  padding-left: 25px;
}

.inner-banner .route-info p:after {
  content: "";
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #e74c3c;
}

.inner-banner .route-info p:first-child::after {
  background: #4db282;
}

.inner-banner .route-info .via-point:after {
  background: #2523b6;
}

.inner-banner .step-count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
  padding-top: 20px;
  margin-bottom: 30px;
}

.inner-banner .step-count p {
  margin-bottom: 0;
}

.inner-banner .step-count p span {
  width: 32px;
  height: 32px;
  background: #13123d;
  display: inline-block;
  color: #fff;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  font-size: 16px;
}

.inner-banner .step-count p.active {
  font-weight: 500;
  font-size: 18px;
}

.inner-banner .step-count p.active span {
  background-color: #4db282;
  margin-right: 10px;
  font-size: 16px;
}

.inner-banner .distance-duration {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  gap: 1rem;
}

.inner-banner .selected-vehicle {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  gap: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 50px;
  margin-top: 20px;
}

.inner-banner .selected-vehicle img {
  width: 130px !important;
}

.inner-banner .selected-vehicle .capacity-wrap {
  margin: 0 !important;
}

.inner-banner .selected-vehicle .capacity-wrap img {
  width: 30px !important;
}

.inner-banner .date-time-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;
}

.inner-banner .date-time-wrapper div {
  padding: 5px 25px;
  border: 2px solid #13123d;
  border-radius: 10px;
  background-color: #fff;
}

.inner-banner .date-time-wrapper .date-wrap {
  font-size: 36px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.inner-banner .date-time-wrapper .date-wrap span {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.inner-banner .date-time-wrapper .date-wrap span strong {
  display: block;
}

.inner-banner .date-time-wrapper .time-wrap {
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

.inner-banner .date-time-wrapper .time-wrap strong {
  font-size: 22px;
  display: block;
}

.inner-banner .selected-vehicle h6 {
  font-weight: 400;
  color: #565656;
  font-size: 16px;
  margin-bottom: 20px;
}

.inner-banner .selected-vehicle h2 {
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #13123d;
  margin-bottom: 30px;
}

/* .inner-banner .selected-vehicle img {
  width: 100%;
} */

.inner-banner .selected-vehicle .capacity-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
  gap: 30px;
  font-weight: 600;
  color: #444;
  margin-top: 30px;
}

.inner-banner .selected-vehicle .capacity-wrap span img {
  padding-right: 10px;
  width: auto;
}

.vehicle-select-wrapper .container {
  width: 70%;
}

.vehicle-select-wrapper .single-vehicle-select {
  border-bottom: 1px dashed #888;
  padding: 40px 0;
}

.vehicle-select-wrapper .single-vehicle-select:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.vehicle-select-wrapper .single-vehicle-select h3 {
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
}

.vehicle-select-wrapper .single-vehicle-select .capacity-wrap {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  font-weight: 600;
  color: #444;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.vehicle-select-wrapper .single-vehicle-select .capacity-wrap span {
  margin-right: 30px;
}

.vehicle-select-wrapper .single-vehicle-select .capacity-wrap span img {
  margin-right: 10px;
  width: 24px;
}

.vehicle-select-wrapper .single-vehicle-select ul {
  padding-left: 0;
}

.vehicle-select-wrapper .single-vehicle-select img.vehicle-show {
  width: 100%;
  height: 120px;
  -o-object-fit: contain;
  object-fit: contain;
}

.vehicle-select-wrapper .trip-price-wrapper {
  background: #edf1ff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  float: right;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}

.vehicle-select-wrapper .trip-price-wrapper h2 {
  font-weight: 700;
  font-size: 28px;
  color: #13123d;
}

.vehicle-select-wrapper .trip-price-wrapper h2 sub {
  font-size: 13px;
  padding-left: 10px;
}

.vehicle-select-wrapper .trip-price-wrapper .btn-main {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.booking-form-wrapper {
  margin-top: 40px;
}

.booking-form-wrapper .booking-form {
  width: 60%;
  margin: 0 auto;
  padding: 25px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.3);
}

.booking-form-wrapper .booking-form h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.booking-form-wrapper .booking-form input,
.booking-form-wrapper .booking-form select {
  height: 55px;
  border: 1px solid #696969;
  border-radius: 6px;
}

.booking-form-wrapper .booking-form textarea {
  border: 1px solid #696969;
  border-radius: 6px;
}

.booking-form-wrapper .booking-form .btn-main {
  width: 100%;
}

/* .terms-wrapper {
  margin-top: 150px;
  padding-bottom: 80px;
} */

.terms-wrapper h1 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #13123d;
}

.terms-wrapper p {
  line-height: 28px;
}

.terms-wrapper h4 {
  margin-top: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #13123d;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 28px;
  margin-left: 20px;
}

.switch input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch .slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input[type="checkbox"]:checked + .slider {
  background-color: #4db282;
}

.switch input[type="checkbox"]:checked + .slider:before {
  -webkit-transform: translateX(27px);
  transform: translateX(27px);
}

.centered-modal .modal-dialog {
  top: 30%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.thankyou-banner {
  background: #edf1ff;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.thankyou-banner img {
  width: 350px;
  height: 250px;
  -o-object-fit: contain;
  object-fit: contain;
  margin-top: 50px;
}

.thankyou-banner h1 {
  margin-top: 30px;
  position: relative;
  font-weight: 700;
  font-size: 36px;
  margin-bottom: 25px;
  color: #343434;
}

.thankyou-banner p {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 55px;
}

@media (max-width: 323px) {
  .distance-duration span strong {
    display: block;
  }
  .distance-duration span {
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .vehicle-select-wrapper .single-vehicle-select h3 {
    padding-top: 1rem;
  }

  .quote-form {
    width: 80%;
    margin: auto;
  }

  .col-lg-6 img:nth-child(2) {
    height: 50px;
    width: 57px !important;
  }
  .vehicle-select-wrapper .single-vehicle-select .capacity-wrap {
    padding-top: 0.5rem;
  }

  div.trip-price-wrapper form {
    margin-right: 30px;
  }
  .container {
    max-width: 100%;
  }

  /* .navbar-expand .navbar-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 2;
  } */
}

@media (max-width: 991px) {
  .booking-form-wrapper .container {
    max-width: 100%;
    width: 100%;
  }
  .ride-info {
    margin-bottom: 30px;
  }
  .call-to-book-wrapper .container {
    margin-bottom: 30px;
  }
  .main-banner {
    padding-top: 15px;
  }
  .about-feature-wrapper .arrow-feature h4 {
    line-height: 32px;
  }
  .touch-form h4 {
    font-size: 30px;
  }

  .get-touch-wrapper {
    padding: 50px 0;
  }
  .fleet-wrapper .container {
    width: 95%;
  }
  .section-gap {
    padding: 30px;
    padding-top: 40px;
  }

  .mt-5 {
    margin-top: 0 !important;
  }
  .section-title {
    font-size: 38px;
    margin-bottom: 22px;
  }
  .main-banner .banner-text h1 {
    font-size: 37px;
    line-height: 55px;
  }
  .booking-form-wrapper .booking-form {
    width: 100% !important;
  }
  .inner-banner .selected-vehicle {
    display: inline-block;
  }

  .footer_wrapper p {
    text-align: center;
    font-size: 13px;
  }

  button.btn.btn-main {
    padding: 11px 17px;
    font-size: 16px;
    border: 0px;
  }
  .footer_wrapper .ft-links {
    text-align: center;
    padding-right: 3rem;
  }

  div.footer_wrapper div.row {
    flex-direction: column-reverse;
    padding: 0;
    font-size: 18px;
  }

  .vehicle-select-wrapper .container {
    width: 95% !important;
  }
  div.vehicle-select-wrapper.section-gap {
    padding-bottom: 3rem;
  }
  a.navbar-brand {
    font-size: 17px !important;
  }

  .main-banner .banner-img {
    text-align: center !important;
  }

  .main-banner .banner-text {
    margin-top: 63px;
  }

  .quote-form .nav-tabs .nav-link {
    font-size: 18px;
    padding: 12px 32px;
    width: 95%;
  }
  .call-to-book-wrapper .container {
    width: 95%;
    margin-top: 50px;
  }
  .navbar__wrapper.fixed {
    position: absolute;
  }
  .col-lg-6 img:nth-child(2) {
    height: 55px;
    width: 65px !important;
  }

  .call-to-book-wrapper .container a {
    padding-left: 17px;
    font-size: 28px;
    font-weight: 550;
  }

  .call-to-book-wrapper .container .car-name {
    top: 0;
    width: 300px;
    transform: scaleX(-1);
    right: 0;
  }
  div.call-link a img {
    height: 50px;
  }

  div.whatsapp-logo a img {
    height: 50px !important;
  }
  .navbar-expand .navbar-nav {
    display: none;
  }

  .navbar-contacts {
    position: fixed;
    bottom: 0;
    background: #eeeeee;
    padding: 10px;
    z-index: 9999;
    display: flex !important;
    justify-content: space-around;
  }

  .about-feature-wrapper .arrow-feature h4 {
    width: 100%;
    text-align: center;
  }
  div.mt-5.pt-5.row {
    padding-top: 0;
  }
  .two-col-hov-wrapper .single-col-hov .single-col-desc p {
    text-align: center;
  }

  .two-col-hov-wrapper .single-col-hov .single-col-desc h5 {
    text-align: center;
  }
  .about-feature-wrapper .col-lg-3:nth-child(odd) .arrow-feature h4:after {
    display: none;
  }
  .about-feature-wrapper .col-lg-3:nth-child(even) .arrow-feature h4:after {
    display: none;
  }
  .footer_wrapper {
    padding-bottom: 104px;
    padding-top: 32px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  /* .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-control,
  .input-group:not(.has-validation)
    > .form-floating:not(:last-child)
    > .form-select,
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(
      .form-floating
    ) {
    display: flex;
    flex-grow: 1;
  } */
  /* .navbar-expand .navbar-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    justify-content: space-around;
    align-items: center;
    height: 5rem;
  } */

  .vehicle-select-wrapper .single-vehicle-select .capacity-wrap {
    gap: 0px;
    margin-right: 12px;
  }

  .vehicle-select-wrapper .single-vehicle-select .capacity-wrap span {
    margin-right: 26px;
  }

  .vehicle-select-wrapper .single-vehicle-select .capacity-wrap span img {
    height: 23px;
  }
  .navbar__wrapper .navbar-brand img {
    padding-right: 5px;
  }

  a.nav-link img {
    height: 50px;
    width: 48px;
  }

  a.call-link.nav-link {
    font-size: 18px !important;
    margin-right: 10px !important;
  }

  a.call-link.nav-link img {
    padding-right: 3px;
    height: 50px;
    width: 55px;
  }

  .vehicle-select-wrapper .trip-price-wrapper h2 sub {
    display: inline-flex;
  }
}

@media (max-width: 767px) {
  .thankyou-banner h1 {
    font-size: 18px;
  }
  .section-title {
    margin-bottom: 1rem;
  }
  .ride-info {
    margin-bottom: 70px;
  }
  .centered-modal .modal-dialog {
    width: 80%;
  }
  .main-banner .banner-text {
    margin-top: 62px;
  }

  .call-to-book-wrapper .container a {
    font-size: 24px;
    padding-left: 15px;
  }
  .vehicle-select-wrapper .single-vehicle-select img.vehicle-show {
    padding-bottom: 10px;
  }

  .vehicle-select-wrapper .container {
    width: 95% !important;
  }
  section.inner-banner {
    top: 72px;
  }
  .navbar__wrapper .navbar-brand img {
    padding-right: 3px;
  }

  a.call-link.nav-link {
    font-size: 14px !important;
    margin-right: 8px !important;
  }

  a.call-link.nav-link img {
    padding-right: 3px !important;
    height: 50px;
  }

  a.nav-link img {
    height: 50px;
  }

  a.navbar-brand {
    letter-spacing: 0 !important;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
  a.call-link.nav-link {
    letter-spacing: 0 !important;
  }
}

@media (max-width: 640px) {
  .vehicle-select-wrapper .container {
    width: 95% !important;
    max-width: 100%;
  }

  .main-banner .banner-text {
    margin-top: 35px !important;
  }
  .two-col-hov-wrapper .single-col-hov .single-col-desc h5 {
    font-size: 21px;
  }
  .two-col-hov-wrapper .single-col-hov .single-col-desc {
    padding: 10px;
  }

  .pt-5 {
    padding-top: 1rem !important;
  }

  .about-feature-wrapper .arrow-feature h4 {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 32px;
  }
  .about-feature-wrapper .arrow-feature {
    margin-top: 23px;
  }

  .navbar-expand .navbar-nav {
    height: 4rem !important;
  }
  .fleet-wrapper .fleet-box {
    margin-bottom: 30px;
    padding: 30px 0;
  }
  .fleet-wrapper .fleet-box {
    width: 100%;
  }
  .main-banner {
    padding-top: 43px;
  }
  .main-banner .banner-text h1 {
    font-size: 25px;
    line-height: 64px;
  }

  div.footer_wrapper div.row {
    padding: 0;
  }
  .footer_wrapper .ft-links {
    font-size: 16px;
  }

  .section-gap {
    padding-top: 25px;
  }
  .section-title {
    font-size: 25px;
  }
  section.about-feature-wrapper.section-gap.pb-4 {
    padding: 50px 0;
  }

  .touch-form h4 {
    font-size: 21px;
  }
  .touch-form {
    padding: 36px;
  }

  .call-to-book-wrapper .container h5 {
    font-size: 21px;
  }
  .fleet-wrapper .container {
    width: 90%;
  }
  .quote-form .nav-tabs .nav-link {
    padding: 12px 30px;
    font-size: 20px;
    width: 100%;
  }
  .fleet-wrapper .fleet-box img {
    height: auto;
  }

  .fleet-wrapper .fleet-box h4 {
    margin-bottom: 10px;
  }

  .call-to-book-wrapper .container a {
    font-size: 18px;
    padding-left: 12px;
  }

  .call-to-book-wrapper .container .car-name {
    top: 0;
    width: 225px;
    transform: scaleX(-1);
    right: 0;
  }

  .call-to-book-wrapper .container h5 {
    margin-bottom: 10px;
  }

  .col-lg-6 img:nth-child(2) {
    height: 38px;
    width: 45px !important;
  }
  section.fleet-wrapper.section-gap {
    padding: 10px 0;
  }

  .get-touch-wrapper {
    padding: 50px 0;
  }
  .navbar__wrapper .navbar-brand img {
    padding-right: 1px;
  }

  a.call-link.nav-link span {
    font-size: 11px !important;
    margin-right: 7px !important;
    display: none;
  }

  a.call-link.nav-link img {
    padding-right: 3px !important;
    height: 45px;
  }

  a.nav-link img {
    height: 45px;
  }

  a.navbar-brand span {
    letter-spacing: 0 !important;
    font-size: 13px !important;
    padding-left: 3px;
  }

  .navbar-expand .navbar-nav .nav-link {
    padding-right: 12px;
    padding-left: 0;
  }
  a.call-link.nav-link {
    letter-spacing: 0 !important;
  }

  section.inner-banner {
    top: 71px;
  }
}

@media (max-width: 480px) {
  div.trip-price-wrapper form {
    margin-right: 20px;
  }

  .about-feature-wrapper .arrow-feature h4 {
    font-size: 22px !important;
    line-height: 29px !important;
  }

  button.btn.btn-main {
    padding: 11px 15px;
  }
  .fleet-wrapper .fleet-box .capacity-wrap {
    gap: 11px;
  }

  .fleet-wrapper .fleet-box {
    width: 100%;
  }

  .fleet-wrapper .fleet-box {
    padding: 22px 0;
  }
  .footer_wrapper {
    padding-bottom: 84px;
    padding-top: 12px;
  }
  .call-to-book-wrapper .container .col-lg-6 {
    text-align: center;
  }
  .call-to-book-wrapper .container {
    padding: 120px 30px 30px;
    margin-top: 32px;
  }
  .call-to-book-wrapper .container .car-name {
    width: 193px;

    right: 0;
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%) scaleX(-1);
  }

  .two-col-hov-wrapper .single-col-hov .single-col-desc {
    position: static;
  }

  .two-col-hov-wrapper .single-col-hov {
    margin-bottom: 30px !important;
  }

  .section-gap {
    padding: 10px;
  }
  .two-col-hov-wrapper .single-col-hov img {
    height: auto;
    width: 100%;
    object-fit: contain;
  }
  .footer_wrapper .ft-links {
    font-size: 14px;
  }

  .quote-form .nav-tabs .nav-link {
    padding: 9px 18px !important;
    font-size: 14px;
    width: 100%;
  }
  .quote-form {
    padding: 17px;
    width: 100%;
  }
  .footer_wrapper .ft-links {
    padding-right: 2rem;
  }
  .navbar__wrapper {
    padding: 2px 0;
  }

  section.inner-banner {
    top: 45px;
  }

  a.call-link.nav-link img {
    padding-right: 0;
    height: 45px;
  }

  a.nav-link img {
    height: 45px;
  }

  .navbar-expand .navbar-nav {
    align-items: center;
  }

  div.vehicle-select-wrapper.section-gap {
    padding-bottom: 2rem;
  }

  .main-banner .banner-text h4 {
    font-size: 20px;
  }
  .main-banner .banner-text h1 {
    line-height: 50px;
  }
  .main-banner {
    padding-top: 2rem;
  }
  div.banner-text h4 {
    margin-bottom: 0;
  }
  /* 
  .quote-form select.pac-target-input,
  .quote-form input.pac-target-input {
    width: auto;
  } */
}

@media (min-width: 576px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .vehicle-select-wrapper .single-vehicle-select img.vehicle-show {
    padding-bottom: 10px;
  }
  .inner-banner .ride-info {
    position: absolute;
    left: 150px;
    top: 6%;
  }
  .inner-banner .selected-vehicle {
    display: flex;
  }

  .container {
    max-width: 86% !important;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

div.mb-3.input-group {
  flex-wrap: nowrap;
}

@media (max-width: 1200px) {
  .about-feature-wrapper .arrow-feature h4 {
    font-size: 18px;
  }
  a.call-link.nav-link span {
    display: none;
  }
  .quote-form .nav-tabs .nav-link {
    padding: 15px 22px;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  .main-banner .banner-text h1 {
    font-size: 58px !important;
  }

  .navbar__wrapper .nav-link.call-link img {
    height: 50px;
  }
  a.nav-link img {
    height: 50px;
  }
}

.navbar-contacts {
  display: none;
}

@media (max-width: 1440px) {
  .about-feature-wrapper .col-lg-3:nth-child(even) .arrow-feature h4:after {
    top: 14px;
    right: -82px;
  }

  .about-feature-wrapper .arrow-feature h4 {
    font-size: 25px;
    line-height: 37px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 16px;
  width: 12px;
  height: 12px;
  margin-top: -0.5em;
  animation: spinner 1800ms infinite linear;
  border-radius: 0.5em;
  box-shadow:
    rgba(77, 179, 131, 1) 1.5em 0 0 0,
    rgba(77, 179, 131, 1) 1.1em 1.1em 0 0,
    rgba(77, 179, 131, 1) 0 1.5em 0 0,
    rgba(77, 179, 131, 1) -1.1em 1.1em 0 0,
    rgba(77, 179, 131, 1) -1.5em 0 0 0,
    rgba(77, 179, 131, 1) -1.1em -1.1em 0 0,
    rgba(77, 179, 131, 1) 0 -1.5em 0 0,
    rgba(77, 179, 131, 1) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* Loading */

#wifi-loader {
  --background: #62abff;
  --front-color: #4f29f0;
  --back-color: #c3c8de;
  --text-color: #414856;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}

#wifi-loader svg circle.back {
  stroke: var(--back-color);
}

#wifi-loader svg circle.front {
  stroke: var(--front-color);
}

#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}

#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
  animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
  animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}

#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
  animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
  animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}

#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
  animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
  animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text-loader {
  position: absolute;
  top: 140%;
  left: 140%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.2px;
  transform: translate(-50%, -50%);
}

#wifi-loader .text-loader::before,
#wifi-loader .text-loader::after {
  content: attr(data-text);
  width: 400px;
}

#wifi-loader .text-loader::before {
  color: var(--text-color);
}

#wifi-loader .text-loader::after {
  color: var(--front-color);
  animation: text-animation76 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@keyframes circle-outer135 {
  0% {
    stroke-dashoffset: 25;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 301;
  }

  80% {
    stroke-dashoffset: 276;
  }

  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-middle6123 {
  0% {
    stroke-dashoffset: 17;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 204;
  }

  80% {
    stroke-dashoffset: 187;
  }

  100% {
    stroke-dashoffset: 187;
  }
}

@keyframes circle-inner162 {
  0% {
    stroke-dashoffset: 9;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 106;
  }

  80% {
    stroke-dashoffset: 97;
  }

  100% {
    stroke-dashoffset: 97;
  }
}

@keyframes text-animation76 {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  50% {
    clip-path: inset(0);
  }

  100% {
    clip-path: inset(0 0 0 100%);
  }
}

.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow: hidden;
}
#wifi-loader {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%s);
}

.quote-form input:focus {
  outline: none;
  box-shadow: none;
  border-color: #000;
}

.quote-form {
  border: 1px solid #00000078;
}

.quote-form .btn-main:hover {
  color: white;
  /* background-color: #3d9168 !important; */
}

.navbar__wrapper {
  display: block;
}

@media only screen and (min-width: 768px) {
  .cta-button {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .main-banner {
    margin-top: 20px;
  }

  .header-wrapper {
    position: fixed;
    background: #fff;
    top: 0;
    padding: 10px 15px;
  }
  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .head .logo-title {
    font-size: 18px;
    /* background-color: #4db282; */
    width: 70% !important;
  }
  .head .cta-button {
    /* background-color: grey; */
    width: 30% !important;
  }
  .call-link {
    margin-right: 20px;
  }
  .cta-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .demo-tag {
    position: fixed !important;
    bottom: 0 !important;
    top: initial !important;
    width: 100%;
    font-size: 22px !important;
  }
  .footer_wrapper {
    padding-bottom: 100px !important;
  }
}

.quote-form .btn-main {
  background-color: #00914c !important;
  padding: 10px 24px !important;
}

.navbar__wrapper .btn-main {
  background-color: #00914c !important;
  padding: 10px 24px !important;
}

.get-touch-wrapper .btn-main {
  background-color: #00914c !important;
  padding: 10px 24px !important;
  margin-top: 10px;
}

.logo-title {
  color: #3b3b3b;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
}

.logo-title:hover {
  color: #8b8b8b;
}

.custom-btn {
  background-color: #00914c !important;
  color: #fff;
  border: 2px solid #4db282;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 24px;
  border-radius: 5px;
  text-transform: capitalize;
  width: 100%;
}
.custom-btn-1 {
  background-color: #2e7de8 !important;
  color: #fff;
  border: 2px solid #4db282;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 24px;
  border-radius: 5px;
  text-transform: capitalize;
  width: 100%;
}

.form-control {
  font-weight: 600 !important;
}

.selected-vehicle {
  width: 100%;
  height: max-content !important;
  text-align: center;
}

/* .inner-banner .selected-vehicle .selected-fleet-ctn {
  height: 100%;
} */

.inner-banner .selected-vehicle .selected-fleet-ctn img {
  width: 100% !important ;
  height: 100px !important;
  object-fit: contain;
}

.call-to-book-wrapper .container .car-name {
  position: absolute;
  top: -140px !important;
  width: 500px !important;
}

/* .quote-form .nav-tabs .nav-link {
  border: 2px solid #000 !important;
} */

.quote-form .nav-tabs .nav-link.active {
  color: #4db282;
  border: 3px solid #4db282 !important;
}

.quote-form .nav-tabs .nav-link {
  border: 2px solid #3b3b3b !important;
  border-radius: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 17px;
  color: #3b3b3b !important;
  padding: 15px 32px;
  width: 100%;
}

.fleet-wrapper .fleet-box {
  border: 2px solid #00000033;
}

.switch .slider {
  background-color: #000 !important;
}

.switch input[type="checkbox"]:checked + .slider {
  background-color: #00914c !important;
}

/* cookies-consent */
#termsfeed-com---nb {
  box-shadow: 5px 5px 40px rgba(0, 0, 0, 0.5);
}

#termsfeed-com---nb {
  background-color: #fff;
  border-radius: 10px;
}

#termsfeed-com---nb .cc-nb-title {
  text-align: center;
  font-weight: 600;
}

#termsfeed-com---nb .cc-nb-main-container {
  padding: 15px;
}

.signup-btn {
  background-color: #332f2bf5;
  padding: 8px 10px;
  font-size: 12px;
  text-decoration: none;
  border-radius: 5px;
  margin-left: 12px;
}

.via-ctn {
  button {
    background: #00914c;
    color: white;
    border: 2px solid #4db282;
    border-radius: 0.375rem;
    padding: 0.375rem 0.75rem;
  }
}

.error-centered-modal .modal-dialog {
  top: 50% !important;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.payment-modal {
  h5 {
    text-align: center;
    font-weight: 600;
  }

  .icon-ctn {
    background-color: #ff000066;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}

.footer_wrapper .download-section {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

/* 
.inner-banner .ride-main-details {
  width: 600px !important;
  min-height: 307px !important;
} */

.booking-form {
  .title {
    margin: 0px !important;
  }

  .sub-title {
    margin: 0px !important;
    margin-bottom: 20px !important;
    /* color: #3b3b3b; */
    font-size: 14px;
  }
}

.divider {
  width: 100%;
  height: 1px;
  background: #00000036;
  margin-top: 10px;
  margin-bottom: 10px;
}

.thank-you-ctn {
  min-height: 100vh;
  background-color: #ededed;
  padding-top: 150px;
  /* padding: 0px; */

  .thank-you-card {
    padding: 20px 40px;
    position: relative;
    max-width: 100%;
    margin: 0px auto;
    background-color: #00914c;
    min-height: 50vh;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.2);

    .title {
      padding-top: 54px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ededed;
      font-size: 20px;
      text-align: center;
    }

    .address-card-ctn {
      span {
        background-color: #ec6408;
        color: white;
        padding: 0px 10px;
        border-radius: 25px;
        margin-bottom: 20px;
      }

      .way-line {
        border: 2px dotted #00914c;
        width: 400px;
        height: 1px;
      }

      label {
        font-weight: 500;
      }

      p {
        color: #535558;
        text-align: center;
      }

      .duration-ctn {
        display: flex;
        /* justify-content: center; */
        gap: 20px;
      }
    }

    .address-ctn {
      /* margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center; */

      .address {
        min-width: 200px;
        text-align: start;
      }
    }

    .personal-info-container {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      gap: 30px;
    }
    .personal-info-container .outer {
      display: flex;
      gap: 40px;
      justify-content: center;
    }

    .personal-info-ctn {
      label {
        margin: 0px;
        font-weight: 500;
      }

      p {
        margin: 0px;
        color: #535558;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .fleet-info-ctn {
      margin-top: 40px;

      .fleet-info .fleet-image {
        width: 300px;
        height: 100px;
        margin: 0px;
        object-fit: contain;
      }

      .fleet-info label {
        font-weight: 500;
      }
    }

    .fleet-info-ctn .fleet-info p {
      margin: 0px;
    }
  }

  .fare-container {
    margin-top: 50px;
    border-top: 1px solid #888585;

    .one {
      display: flex;
      justify-content: space-between;

      p {
        margin: 0px;
      }
    }
  }

  .button-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .yes-ctn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    background-color: #5edd608a;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
      height: 70px;
    }
  }
}

/* ===========responsive-block====== */
@media (width < 480px) {
  .thank-you-ctn {
    & .thank-you-card {
      & .address-ctn {
        overflow: hidden;
        .address {
          min-width: 100%;
          text-align: start;

          p {
            text-align: start;
          }
        }
      }
    }
  }

  .thank-you-ctn .way-line {
    display: none;
  }
  .call-to-book-wrapper .container .car-name {
    position: absolute;
    top: -5px !important;
    width: 500px !important;
  }
}

@media (width < 768px) {
  .address-ctn {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .address {
      min-width: 200px;
      text-align: start;
    }
  }

  .thank-you-ctn .way-line {
    display: block;
  }

  #termsfeed-com---nb {
    margin-right: 10%;
    margin-bottom: 160px;
    border-radius: 10px;
  }
  #termsfeed-com---nb .cc-nb-main-container {
    padding: 30px;
  }
}

@media (width > 769px) {
  /* .thank-you-ctn { */
  .address-ctn .address p {
    text-align: center;
  }

  .thank-you-ctn {
    & .thank-you-card {
      & .address-ctn {
        .address {
          width: 200px;
          text-align: center;
        }
      }
    }
  }

  .thank-you-ctn {
    /* padding: 150px 0px; */

    .button-group {
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .duration-ctn {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }

  .thank-you-ctn .thank-you-card {
    max-width: 1000px;
  }

  #termsfeed-com---nb {
    background-color: #fff;
    margin-right: 23%;
    margin-bottom: 60px;
    border-radius: 10px;
    /* height: max-content; */
    /* height: 290px; */
  }
  #termsfeed-com---nb .cc-nb-buttons-container {
    display: flex;
    justify-content: center;
  }
}

@media (width > 992px) {
  .ride-main-details {
    width: 601px;
  }
}
